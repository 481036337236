<template>
   <div>
     <v-banner single-line class="banner h5-carousel-new">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/num-banner-h5-FTE.png"
        height="100%"
        :transition="false"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <div class="h5-banner-info" style="margin-top: -40px;">
        <div class="width-per-100">
          <div class="h5-banner-title">FT引擎</div>
          <!-- <div class="h5-banner-text">轻量易用的跨平台3D编辑工具
              自主研发的三维引擎，可视化便捷操作，让你快速创作虚拟内容</div> -->
        </div>
      </div>
    </v-banner>

    <div class="con con1">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>强大的功能 助力高效3D内容制作</span>
            <span>3D Content production</span>
          </div>
        </div>
        <div class="mt-4">
          <v-row v-for="(row,rowIndex) in list1" :key="rowIndex">
            <v-card class="pa-3 d-flex h5-card" min-height="90" width="100%" flat>
              <v-img :src="row.icon" max-width="67" max-height="67" contain position="top"></v-img>
              <div class="ml-4">
                <v-card-title class="pa-0 mt-1 font-size-16 line-height-20">
                  {{row.title}}
                </v-card-title>
                <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                  {{row.text}}
                </v-card-text>
              </div>
            </v-card>
          </v-row>
        </div>
      </v-card>
    </div>
    <div class="con con2">
      <div class="box">
        <div class="box-img">
          <v-img src="https://h5.ophyer.cn/official_website/other/fte-con2-img01.png" width="100%" contain>
            <template v-slot:placeholder><img-placeholder></img-placeholder></template>
          </v-img>
        </div>
        <p>技术框架</p>
      </div>
      <div class="box">
        <div class="box-img">
          <v-img src="https://h5.ophyer.cn/official_website/other/fte-con2-img02.png" width="100%" contain>
            <template v-slot:placeholder><img-placeholder></img-placeholder></template>
          </v-img>
        </div>
        <p>功能结构</p>
      </div>
    </div>

    <div class="con con3">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>内容创作</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <div class="box">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/fte-con3-img01.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>支持多种类型内容创作</p>
            <p>动画，灯光，粒子，物理，地形，材质全部都支持，通过强<br>大的物理引擎，实现逼真的物理反馈</p>
          </div>
        </div>
      </v-card>
    </div>
    <div class="con con3" style="background: #fff;">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>逻辑开发</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <div class="box">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/fte-con3-img02.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>让场景动起来</p>
            <p>支持蓝图式开发，简单拖拽即可实现逻辑嵌入，提 <br> 供完善的接口，同时支持C#及JS</p>
          </div>
        </div>
      </v-card>
    </div>

    <div class="con con3">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>实时渲染</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <div class="box">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/fte-con3-img03.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>实现完美效果</p>
            <p>全局光照及混合渲染等诸多强大的渲染技术，让画面的表现 <br> 趋近完美</p>
          </div>
        </div>
      </v-card>
    </div>
    <div class="con con3" style="background: #fff;">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>后期处理</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <div class="box">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/fte-con3-img04.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>让内容趋于完美</p>
            <p>支持屏幕反射、景深、色彩校正、全屏泛光、环境光、抗锯 <br> 齿及阳光射线等后期处理</p>
          </div>
        </div>
      </v-card>
    </div>

    <div class="con con3">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>支持多平台</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <div class="box">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/fte-con3-img05.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>随时随地进行创作</p>
            <p>支持安卓，IOS，Mac,Window等常见的操作系统，让你随<br>时随地开始创作</p>
          </div>
        </div>
      </v-card>
    </div>
    <div class="con con3" style="background: #fff;">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>海量素材资源</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <div class="box">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/fte-con3-img06.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="box-text">
            <p>大量云端资源</p>
            <p>拥有丰富的3D模型，场景，贴图，粒子特效等资源，助力快速创建场景</p>
          </div>
        </div>
      </v-card>
    </div>
    <div class="con con4">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>内容发布</span>
            <span>aPaaS engine</span>
          </div>
          <p>支持各种XR硬件，支持HTML5 WebGL平台</p>
        </div>
        <ul>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon01-01.png" alt="">
            <p>PC</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon02-01.png" alt="">
            <p>移动端</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon03-01.png" alt="">
            <p>WEB端</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon04-01.png" alt="">
            <p>VR头显</p>
          </li>
           <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon05-01.png" alt="">
            <p>MR头显</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon06-01.png" alt="">
            <p>沉浸CAVE</p>
          </li>
        </ul>
      </v-card>
    </div>
   </div>
</template>

<script>
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      list1: [
        {
          icon: 'https://h5.ophyer.cn/official_website/icon/fte-con1-icon01.png',
          title: '操作简单',
          text: '供图形化的编辑页面，让3D场景搭建，炫酷动画的制作变得简单',
        },
        {
          icon: 'https://h5.ophyer.cn/official_website/icon/fte-con1-icon02.png',
          title: '功能全面',
          text: '海量素材，多种内容创作支持，逻辑开发，让创作更随心',
        },
        {
          icon: 'https://h5.ophyer.cn/official_website/icon/fte-con1-icon03.png',
          title: '强大的渲染能力',
          text: '强大的渲染能力，让材质贴图，光效等画面细节都逼真无比',
        }
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
  
  }
}
</script>

<style scoped lang='scss'>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.banner_title{
  font-size: 60px;
  color: #FFFFFF !important;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}

.con {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.con1 {
  background: #FBFBFB;
  padding: 0 20px;
} 

.con2 {
  
  margin-bottom: 35px;
  padding: 25px 20px 0;
  display: flex;
  justify-content: space-between;
  .box {
    text-align: center;
    margin-top: 15px;
    width: 47%;
  }
  .box-img {
    width: 100%;
    img {
      width: 100%;
      // height: 70px;
      border-radius: 10px;
    }
  }
  p {
    margin-bottom: 0;
    margin-top: 15px;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }
}
  
.con3 {
  background: #FBFBFB;
  padding-bottom: 30px;
  .v-card {
    background: #FBFBFB;
  }
  .box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    margin: 10px auto;
    padding: 0 20px;
  }
  .box-img {
    width: 100%;
    img {
      width: 100%;
      height: 270px;
    }
  }
  .box-text {
    margin-top: 30px;
    p:nth-child(1) {
      font-size: 18px;
      font-weight: 400;
      position: relative;
      margin-bottom: 0;
      &::after {
        content: '';
        width: 40px;
        height: 3px;
        background: #000;
        position: absolute;
        left: 0;
        top: -16px;
      }
    }
    p:nth-child(2) {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 13px;
      font-weight: 300;
      line-height: 22px;
    }
  }
}

.con4 {
  background: #FBFBFB;
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0 !important;
  }
  li {
    width: 160px;
    height: 120px;
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px 10px;
    padding: 10px;
    img {
      width: 56px;
    }
    p {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>